import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import moment from 'moment'
import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'

import storeModule from './storeModule'

export default function () {
  const { makeToast } = useToast()
  const APP_STORE_MODULE_NAME = 'app-receipt-list'

  const initialSearchParams = ref({})
  const currentPage = ref(parseInt(router.history.current.query.page, 10) || 1)
  const searchParams = computed(() => {
    const routerQuery = router.history.current.query
    return {
      page: currentPage.value,
      size: parseInt(routerQuery.size, 10) || 20,
      total: parseInt(routerQuery.total, 10) || 0,
      ...initialSearchParams.value,
    }
  })
  const syncronizeParamsWithUrl = () => {
    const routerQuery = router.history.current.query
    initialSearchParams.value = Object
      .keys(routerQuery)
      .reduce((previoutValue, currentValue) => {
        if (['page', 'size', 'total'].includes(currentValue)) {
          return previoutValue
        }
        if (currentValue === null || currentValue === '') {
          return previoutValue
        }
        if (typeof routerQuery[currentValue] === 'string') {
          return {
            ...previoutValue,
            [currentValue]: [routerQuery[currentValue]],
          }
        }
        return {
          ...previoutValue,
          [currentValue]: routerQuery[currentValue],
        }
      },
      {})
  }

  const replaceRouterQuery = () => {
    router.replace({
      query: { ...searchParams.value },
    }).catch(() => {})
  }

  const categoryOptions = [
    {
      label: '생성일자',
      value: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const tableColumns = [
    { key: 'min_idx', label: 'idx' },
    { key: 'member', label: 'member' },
    { key: 'target', label: 'target' },
    { key: 'product_name', label: 'product_name' },
    { key: 'total_amount', label: 'amount' },
    { key: 'cash_receipt_type', label: 'r_type' },
    { key: 'cash_receipt_id', label: 'r_id' },
    { key: 'created_at', label: 'created_at' },
  ]
  const items = ref([])
  const totalRows = ref(parseInt(searchParams.value.total, 10))
  const perPage = ref(parseInt(searchParams.value.size, 10) ?? 20)
  const isBusy = ref(false)

  const fetchItems = () => {
    isBusy.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/fetchItems`, searchParams.value)
      .then(response => {
        const { page_info, data } = response.data
        items.value = data
        totalRows.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        isBusy.value = false
        replaceRouterQuery()
      })
  }

  const shortcutSearch = params => {
    initialSearchParams.value = {
      ...params,
    }
    router.replace({
      query: { ...searchParams.value, page: 1, size: 20 },
    }).catch(() => {})
    fetchItems()
  }

  const search = params => {
    initialSearchParams.value = {
      ...initialSearchParams.value,
      ...params,
    }
    currentPage.value = 1
    router.replace({
      query: { ...searchParams.value, page: currentPage.value },
    }).catch(() => {})
    fetchItems()
  }

  const changePage = params => {
    currentPage.value = params.page
    router.replace({
      query: { ...searchParams.value, ...params },
    }).catch(() => {})
    fetchItems()
  }

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    syncronizeParamsWithUrl()
    fetchItems()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const isDownloadingExcel = ref(false)

  const downloadExcel = () => {
    isDownloadingExcel.value = true
    store
      .dispatch(`${APP_STORE_MODULE_NAME}/downloadExcel`, searchParams.value)
      .then(response => {
        const fileName = `cash_receipt_${moment().format('YYYYMMDDHHmmss')}.xlsx`
        const file = new File([response.data], fileName, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(file)
        downloadFile(url, fileName)
      })
      .catch(() => {
        makeToast('danger', '엑셀 변환에 실패 했습니다')
      })
      .finally(() => {
        isDownloadingExcel.value = false
      })
  }

  return {
    searchParams,
    initialSearchParams,
    categoryOptions,
    tableColumns,
    items,
    totalRows,
    perPage,
    currentPage,
    isBusy,

    fetchItems,
    search,
    changePage,

    downloadExcel,
    isDownloadingExcel,
    shortcutSearch,
  }
}
