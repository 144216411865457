<template>
  <div>
    <tagby-filter
      :categoryOptions="categoryOptions"
      :initial-search-params="initialSearchParams"
      @search="search"
    >
      <template #buttons>
        <b-button
          variant="outline-success"
          class="mr-1"
          :disabled="isDownloadingExcel"
          @click="downloadExcel"
        >
          <b-spinner
            v-if="isDownloadingExcel"
            small
          />
          <span class="align-middle">엑셀 다운로드</span>
        </b-button>
      </template>
    </tagby-filter>
    <tagby-list
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(member)="data">
        <div
          class="d-flex"
          style="width: 80px"
        >
          <span>
            {{ data.item.member_type }}
          </span>
          <b-link
            v-if="data.item.member_type === 'INF'"
            :to="{ name: 'member-inf-detail', params: { idx: data.item.member_idx } }"
            class="font-weight-bold d-block text-nowrap"
          >
            ({{ data.item.member.name }})
          </b-link>
          <span v-else>
            ({{ data.item.member.name }})
          </span>
        </div>
      </template>

      <template #cell(target)="data">
        <div class="d-flex">
          <span>
            {{ data.item.target_type }}
          </span>
          <b-link
            :to="{ name: 'commerce-order-list', query: { order_no: data.item.target_signature } }"
            class="font-weight-bold d-block text-nowrap"
          >
            ({{ data.item.target_signature }})
          </b-link>
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="max-width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>
    </tagby-list>
  </div>
</template>

<script>
import {
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import TagbyFilter from '@/components/TagbyFilter.vue'
import TagbyList from '@/components/TagbyList.vue'
import resolveValue from '@/utils/resolveValue'

export default {
  components: {
    BLink,
    BButton,
    BSpinner,
    TagbyFilter,
    TagbyList,
  },
  setup() {
    const {
      resolveDateTime,
    } = resolveValue()
    return {
      resolveDateTime,
      ...viewModel(),
    }
  },
}
</script>
